@import "reset.scss";

html {
  position: fixed;
  left: 0;
  top: 0;
  font-weight: 400;
  -webkit-tap-highlight-color: transparent;
  touch-action: pan-x pan-y;
}

html,
#root {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.container {
  width: 100%;
  height: 100%;
  background-color: #F2F3F7;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 16px 16px;

  [class*="dark"] & {
    background-color: #0a0a0a;
  }
}

.app-container {
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 62px;

  &.home {
    @media screen and (min-width: 980px) {
      margin-top: 152px;
    }
  }

  &:not(.home) {
    @media screen and (min-width: 980px) {
      margin-top: 94px;
    }
  }
}

.fastex-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 40px 40px;
  background-color: #ffffff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  animation: show 240ms ease-in-out;
  @media screen and (max-width: 979px) {
    padding: 0 10px 40px;
  }
  &.start {
    align-items: flex-start;
  }

  [class*="dark"] & {
    background-color: #121416;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fastex-logo-bg {
  display: block;
  width: 197px;
  height: 40px;
  background-image: url("./../assets/image/new-logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;

  [class*="dark"] & {
    background-image: url("./../assets/image/new-logo-dark.png");
  }
}

.back-block {
  width: 100%;
  height: 96px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  @media screen and (max-width: 979px) {
    height: 80px;
  }

  .country-dropdown-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .country-dropdown-label {
      margin-right: 10px;
      text-transform: capitalize;
      margin-bottom: 0;
      font-size: 16px;

      &:after {
        content: ":";
      }
    }
  }

  .country-dropdown {
    margin-bottom: 0;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #e4e6ee;

    [class*="dark"] & {
      background-color: #2f333d;
    }
  }

  a {
    font-size: 26px;
    line-height: 29px;
    text-transform: capitalize;
    font-weight: 700;
    color: #000000;

    @media screen and (max-width: 979px) {
      font-size: 20px;
      line-height: 24px;
    }

    i {
      font-size: 20px;
      margin-right: 20px;

      @media screen and (max-width: 979px) {
        font-size: 14px;
        margin-right: 10px;
      }
    }

    [class*="dark"] & {
      color: #ffffff;
    }
  }
}

.pay-receive-card-block {
  width: 100%;
  border: 1px solid #E4E6EE;
  border-radius: 10px;
  padding: 15px;
  position: relative;
  margin-bottom: 30px;

  [class*="dark"] & {
    border-color: #2f333d;
  }

  & ~ .address-block {
    position: relative;
    margin-bottom: 60px;

    &:after {
      content: '';
      position: absolute;
      bottom: -30px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #e4e6ee;

      [class*="dark"] & {
        background-color: #2f333d;
      }
    }
  }
}

.pay-receive-card-row {
  position: relative;
  width: 100%;

  &:not(:last-child) {
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid #E4E6EE;

    [class*="dark"] & {
      border-color: #2f333d;
    }
  }
}

.estimate-text {
  width: 100%;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  color: #697082;
  text-transform: capitalize;
  margin-bottom: 30px;
}

.from-text {
  font-size: 12px;
  line-height: 14px;
  color: #697082;
  text-transform: capitalize;
  display: block;
  margin-bottom: 6px;

  a {
    color: inherit;
  }

  &.center {
    text-align: center;
  }
}

.from-block-items {
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
  background-color: #f2f3f7;
  border-radius: 10px;
  padding: 5px 5px 5px 15px;
  position: relative;

  [class*="dark"] & {
    background-color: #0a0a0a;
    padding-right: 0;
    padding-left: 0;
  }

  .country-dropdown {
    margin-bottom: 0;
  }

  .country-dropdown-selected {
    background-color: #ffffff;
    height: 40px;
    padding-left: 6px;
    font-size: 12px;
    white-space: nowrap;

  }

  &.error {
    input {
      border-color: #c90808;
    }
  }
}


.from-inp {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  background-color: transparent;

  [class*="dark"] & {
    color: #ffffff;
  }
}

.address-input {
  outline: none;
  height: 48px;
  width: 100%;
  font-size: 16px;
  background-color: #F2F3F7;
  border-radius: 10px;
  padding: 0 15px;
  border: 1px solid transparent;
  color: #000000;

  &::placeholder {
    color: #99A8BF;
  }

  [class*="dark"] & {
    background-color: #0a0a0a;
    color: #fff;

    &::placeholder {
      color: #99A8BF;
    }
  }

  &::placeholder {
    color: rgba(#000000, 0.6);
  }
}

.crypto-dropdown {
  position: relative;
  flex: 0 0 auto;

  &.open {
    .pay-icon {
      transform: rotateX(180deg);
    }

    .crypto-dropdown-content {
      pointer-events: auto;
      transition: 240ms ease-in-out;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
}

.crypto-block-inp {
  display: flex;
  align-items: center;
  min-width: 112px;
  max-width: 200px;
  height: 40px;
  cursor: pointer;
  background-color: #f2f3f7;
  border-radius: 5px;
  padding: 7px;

  [class*="dark"] & {
    background-color: #2f333d;
  }
}

.crypto-dropdown-content {
  position: absolute;
  right: 0;
  top: 90%;
  width: 100%;
  background-color: #F2F3F7;
  z-index: 99;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  max-height: 140px;
  overflow-x: hidden;
  overflow-y: auto;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);

  pointer-events: none;
  padding-top: 3px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    width: calc(100% - 12px);
    height: 1px;
    background-color: #E4E6EE;
  }
}

.crypto-dropdown-row {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 7px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 120ms;

  &:hover {
    background-color: #DADCE2;
  }
}

.crypto-dropdown-column {
  flex: 1;
  text-align: right;
}

.crypto-img-d {
  flex: 0 0 auto;
  object-fit: contain;
  width: 28px;
  height: 28px;
  margin-right: 6px;
}

.crypto-dropdown-text {
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.pay-bg {
  width: 24px;
  height: 24px;
  margin-right: 6px;
}

.pay-receive-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #FFFFFF;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.07);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;

  [class*="dark"] & {
    background-color: #2f333d;
    color: #fff;
  }

  &:active {
    transform: scale(0.98);
  }
}

.pay-text {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
  flex: 1;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  [class*="dark"] & {
    color: #ffffff;
  }
}

.pay-icon {
  flex: 0 0 auto;
  display: block;
  font-size: 10px;
  color: #000000;
  margin-left: 7px;
  transition: transform 120ms;

  [class*="dark"] & {
    color: #ffffff;
  }
}

.change-block {
  width: 40px;
  height: 40px;
  border: 1px solid #E4E6EE;
  border-radius: 50px;
  background: #FFFFFF;
  margin: 12px 0 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icon-change {
  color: #FF008C;
  font-size: 15px;
}

.to-block {
  width: 100%;
}

.address-block {
  width: 100%;
  margin-bottom: 10px;
  position: relative;

  .input-error-text {
    position: absolute;
    left: 0;
    bottom: -15px;
    padding-left: 15px;
  }
}

.input-block {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  position: relative;

  .input-error-text {
    position: absolute;
    left: 0;
    bottom: -10px;
    padding-left: 15px;
  }
}

.input-error-text {
  font-size: 11px;
  line-height: 14px;
  color: #ff0000;
  text-transform: capitalize;
  word-break: break-word;
}

.estimate-block {
  width: 100%;
}

.crypto-address-block {
  width: 48px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F5F9FE;
  border: 1px solid #E4E6EE;
  border-radius: 50px;
}

.crypto-block-address-inp {
  width: 72px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.exchange-btn {
  flex: 0 0 auto;
  width: 100%;
  height: 48px;
  background: #FF008C;
  border-radius: 8px;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    transform: scale(0.98);
  }
}

.exchange-cr-btn {
  outline: none;
  border: none;
  height: 48px;
  padding: 0 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  border-radius: 5px;
  text-transform: capitalize;
  cursor: pointer;

  &.exchange {
    color: #ffffff;
    background-color: #F79E21;
  }

  &.buy-crypto {
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #F79E21;
  }

  &.go-to-exchange {
    background-color: #7ebc0a;
    border: none;
    color: white;
  }

  &:active {
    transform: scale(0.98);
  }
}

.buy-crypto-btn {
  flex: 0 0 auto;
  width: 100%;
  height: 48px;
  background: #FF008C;
  border-radius: 8px;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    transform: scale(0.98);
  }
}

.pay-items-block {
  max-width: 444px;
  width: 100%;
  max-height: 548px;
  height: 100%;
  padding: 32px 32px 23px 32px;
  border-radius: 12px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.next-btn {
  flex: 0 0 auto;
  border: none;
  outline: none;
  cursor: pointer;
  margin-right: 8px;

  > i {
    font-size: 16px;
    color: #000000;
  }
}

.search-block {
  flex: 0 0 auto;
  width: 100%;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid #E4E6EE;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  margin-top: 16px;
  margin-bottom: 32px;
}

.search-block-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 44px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.search-block-icon:before {
  content: '';
  position: absolute;
  right: 0;
  top: 8px;
  width: 1px;
  height: 32px;
  display: block;
  background-color: #E4E6EE;
}

.icon-search {
  font-size: 20px;
  color: #9A9A9A;;
}

.search-inp {
  width: 100%;
  height: 100%;
  padding-left: 54px;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.pay-items-block-content {
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
}

.pay-items-block-scroll {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 4px;
  height: 8px;
  @media screen and (max-width: 979px) {
    height: 4px;
  }
}

::-webkit-scrollbar-track {
  background-color: transparent;
  margin: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #E4E6EE;
  border-radius: 10px;
}

.crypto-items-logo {
  width: 30px;
  height: 30px;
}

.crypto-items-content {
  display: flex;
  padding: 10px;
  border-radius: 5px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.01);
  }
}

.crypto-name-block {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
}

.crypto-name-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
}

.crypto-name-text-small {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #9A9A9A;
  text-transform: capitalize;
}

.info-head {
  flex: 0 0 auto;
  width: 100%;
}

.info-head-usdt-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  //padding-top: 18px;
  //border-top: 1px solid #E4E6EE;
}

.info-head-row {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 15px;
}

.info-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.info-block-crypto-name {
  flex: 0 0 auto;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  text-transform: uppercase;
  color: #000000;
}

.info-crypto-number-text {
  font-size: 12px;
  line-height: 14px;
  color: #7D7D7D;
}

.info-btn-row {
  margin-bottom: 44px;
}

.info-data-text {
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  text-transform: capitalize;
  color: #697082;
  width: 100%;
  background: #F2F3F7;
  border-radius: 10px;
  text-align: center;
}

.info-data-text-2 {
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: #000000;
  padding-bottom: 8px;

  &.center {
    justify-content: center;
  }
}

.transaction-confirmed {
  color: #ffffff;
  background-color: #5DC989;
  border-radius: 5px;
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  text-align: center;
}

.info-data-number {
  padding-left: 4px;
  font-weight: 700;
}

.qr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
}

.qr-holder {
  background: #F2F3F7;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.info-qr {
  display: block;
  width: 170px;
  height: 170px;
  object-fit: contain;
}

.qr-text {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.copy-address-btn {
  min-width: 170px;
  height: 40px;
  border: 1px solid #F79E21;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  > i {
    font-size: 14px;
    margin-right: 9px;
  }

  &.copied {
    border-color: #09ab09;

    i {
      color: #09ab09;
    }

    .copy-address-text {
      color: #09ab09;
    }
  }

  &:active {
    transform: scale(0.98);
  }
}


.copy-address-text {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #000000;
}

.info-bottom {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 30px;
}

.done-row {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;

  > i {
    font-size: 60px;
    color: #31c531;
  }
}

.social-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  flex-wrap: wrap;
  gap: 15px;
}

.social-img-link {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #ffffff;
  color: #697082;
  transition: color 240ms;

  &:hover {
    color: #000000;
  }

  &.icon-skype {
    font-size: 22px;
  }

  &.icon-message {
    font-size: 20px;
  }
}

.social-img-text {
  font-size: 10px;
  line-height: 18px;
  color: #9A9A9A;
  margin-right: 10px;
  text-transform: lowercase;
}

.social-img {
  display: block;
  object-fit: contain;
  width: 24px;
}

.info-text {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #7D7D7D;
  text-transform: capitalize;

  &:not(:last-child) {
    padding-bottom: 8px;
  }

  > i {
    flex: 0 0 auto;
    margin-right: 8px;
    color: #F79E21;
    font-size: 17px;
  }

  &.small {
    font-size: 10px;
    line-height: 12px;
  }
}

.description-text {
  padding: 14px 0 20px 0;
  font-size: 12px;
  line-height: 14px;
  color: #7D7D7D;
}

.info-content-inner {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 50px 0;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px;
  height: 142px;
  border-radius: 50%;
  border: 10px solid #F2F3F7;
  position: relative;
}

.loader-inner {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  animation: loader-a 1s linear infinite;

  &:after {
    content: "";
    display: block;
    position: absolute;
    right: -9px;
    bottom: -9px;
    width: 71px;
    height: 71px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../assets/image/elipse.png");
  }
}

.loader-img {
  flex: 0 0 auto;
  width: 48px;
  object-fit: contain;
  display: block;
}

@keyframes loader-a {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.info-content-inner-img {
  display: block;
  height: 142px;
  width: 142px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../assets/image/done.png");
}

.fastex-content {
  &.failed {
    .transaction-confirmed {
      background-color: #FF6448;
    }

    .info-content-inner-img {
      background-image: url("../assets/image/error.png");
    }
  }
}


@media screen and (max-width: 979px) {
  html {
    background-color: #ffffff;
  }
  .container {
    display: block;
    padding: 0;
    overflow: hidden;

    > div {
      height: 100%;
      min-height: 0;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      overflow-x: hidden;
      overflow-y: auto;
      border-radius: 0;
      padding: 16px;
    }

    .crypto-block-inp {
      max-width: 120px;
    }

    .estimate-block {
      margin-top: auto;
    }
  }
}

.addresses-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 6px;
}

.payment-method {
  transform: translateX(0);
  min-width: 200px;
  height: 86px;
  top: 128px;
  padding: 16px 16px 16px 60px;
  border-radius: 10px;
  gap: 10px;
  background-color: #f2f3f7;
  border: 2px solid transparent;
  cursor: pointer;

  [class*="dark"] & {
    background-color: #2f333d;
  }

  &:not(:last-child) {
    margin-right: 6px;
  }

  &.active {
    border-color: #f79e21;

    &:after {
      font-family: 'icomoon', sans-serif;
      content: "\e906";
      position: absolute;
      top: -8px;
      right: 12px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #f79e21;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 8px;
      color: #ffffff;
    }
  }

  img {
    position: absolute;
    display: block;
    object-fit: contain;
    object-position: center;
    width: 34px;
    height: 34px;
    top: 16px;
    left: 16px;
    background-color: #ffffff;
    border-radius: 10px;
  }
}

.fastex-home-page {
  width: 100%;
  padding-top: 60px;
  margin-bottom: 70px;

  @media screen and (max-width: 979px) {
    padding-top: 40px;
  }
}

.fastex-home-nav-block {
  width: 100%;
  display: grid;
  grid-template-areas: 'buy sell' 'exchange exchange';
  grid-gap: 24px;
  margin-bottom: 24px;

  @media screen and (max-width: 979px) {
    grid-gap: 16px;
  }
}

.fastex-home-nav-el {
  width: 100%;
  height: 190px;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 979px) {
    padding: 20px;
    height: 160px;
  }

  [class*="dark"] & {
    background-color: #181a1e;
  }

  &:before {
    position: absolute;
    font-family: 'icomoon', sans-serif;
    top: 20px;
    right: 20px;

    @media screen and (max-width: 979px) {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.ftn-box {
    grid-area: buy;

    &:before {
      content: "\e90c";
      font-size: 34px;
      color: #ff008c;
      z-index: 1;
    }

    &:after {
      content: '';
      display: block;
      width: 192px;
      height: 182px;
      position: absolute;
      background-color: rgba(255, 0, 140, 0.5);
      top: -117px;
      right: -34px;
      border-radius: 50%;
      filter: blur(2rem);
      opacity: 0.6;
      pointer-events: none;

      @media screen and (max-width: 979px) {
        font-size: 16px;
      }
    }
  }

  &.sell-box {
    grid-area: sell;

    &:before {
      content: "\e90b";
      font-size: 24px;
      color: #000000;

      [class*="dark"] & {
        color: #ffffff;
      }
    }
  }

  &.exchange-box {
    grid-area: exchange;

    &:before {
      content: "\e90a";
      font-size: 36px;
      color: #000000;

      [class*="dark"] & {
        color: #ffffff;
      }
    }
  }
}

.fastex-home-nav-title {
  font-size: 26px;
  line-height: 31px;
  font-weight: 700;
  color: #000000;
  text-transform: capitalize;

  @media screen and (max-width: 979px) {
    font-size: 24px;
    line-height: 28px;
  }

  [class*="dark"] & {
    color: #ffffff;
  }
}

.fastex-home-nav-paragraph {
  font-size: 14px;
  line-height: 16px;
  color: #697082;
  margin-bottom: 6px;
}

.fastex-home-button-group {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
  grid-column-gap: 16px;
}

.fastex-home-button {
  width: 100%;
  height: 74px;
  border-radius: 20px;
  background-color: #ffffff;
  border: none;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 12px;
  cursor: pointer;
  font-size: 20px;
  line-height: 23px;
  color: #697082;
  text-align: left;

  [class*="dark"] & {
    background-color: #181a1e;
  }

  @media screen and (max-width: 979px) {
    font-size: 14px;
    line-height: 16px;
    height: 54px;
    padding-left: 12px;
  }

  img {
    display: block;
    width: 50px;
    height: 50px;
    object-fit: contain;
    object-position: center;

    @media screen and (max-width: 979px) {
      width: 30px;
      height: 30px;
    }
  }
}

.country-dropdown-wrapper {
  width: 100%;
}

.country-dropdown-label {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  margin-bottom: 6px;
  color: #697082;
}

.country-dropdown {
  width: 100%;
  position: relative;
  margin-bottom: 30px;

  &.active {
    .country-dropdown-list {
      display: block;
    }
  }
}

.country-dropdown-selected {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #f2f3f7;
  padding-left: 16px;
  padding-right: 50px;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  position: relative;
  cursor: pointer;
  @media screen and (max-width: 979px) {
    padding-right: 34px;

  }
  [class*="dark"] & {
    background-color: #2f333d;
    color: #ffffff;
  }

  &:before {
    position: absolute;
    font-family: 'icomoon', sans-serif;
    content: "\e907";
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 13px;
    color: #697082;
  }
}

.country-dropdown-list {
  position: absolute;
  top: 100%;
  transform: translateY(5px);
  z-index: 1;
  width: 100%;
  background-color: #f2f3f7;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  display: none;
  padding-top: 8px;
  padding-bottom: 8px;
}

.country-dropdown-li {
  width: 100%;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
}

.action-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.action-button {
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 6px;
  }

  &.outline {
    width: 160px;
    border: 1px solid #e3e6ee;

    [class*="dark"] & {
      color: #99A8BF;
      border-color: #2f333d;
    }

    &.active {
      width: 190px;
      border-color: #f79e21;
      color: #697082;
    }
  }

  &.filled {
    width: 200px;
    background-color: #f79e21;
    color: #ffffff;
  }
}

.exchange-description {
  width: 100%;
  padding-bottom: 30px;
  position: relative;
  margin-bottom: 30px;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 1px;
    background-color: #e4e6ee;

    [class*="dark"] & {
      background-color: #2f333d;
    }
  }
}

.address-block-fields {
  padding-bottom: 30px;
  position: relative;
  margin-bottom: 30px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #e4e6ee;

    [class*="dark"] & {
      background-color: #2F333D;
    }
  }
}

.exchange-desc-row {
  width: 100%;
  height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 2px;
  }
}

.exchange-desc-title {
  font-size: 12px;
  line-height: 14px;
  color: #697082;
}

.exchange-desc-value {
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  font-weight: 400;
  [class*="dark"] & {
    color: #ffffff;
  }
  &.strong {
    font-weight: 700;
  }
}

.exchange-main-block {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
}

.exchange-qr-block {
  width: 200px;
  height: 200px;
  padding: 20px;
  border: 1px solid #e4e6ee;
  border-radius: 20px;
  margin-right: 30px;
  flex: 0 0 auto;

  @media screen and (max-width: 550px) {
    margin-right: 0;
    margin-bottom: 26px;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.exchange-main-content {
  width: 100%;
  min-width: 0;
  flex: 1;
}

.exchange-main-awaiting {
  width: 100%;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f3f7;
  border-radius: 10px;
  font-size: 12px;
  line-height: 26px;
  text-transform: capitalize;
  font-weight: 400;
  margin-bottom: 10px;

  span {
    font-weight: 700;
  }
}

.exchange-main-paragraph {
  width: 100%;
  font-size: 12px;
  line-height: 14px;
  color: #697082;
  margin-bottom: 10px;
}

.exchange-copy-btn {
  width: 100%;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #f79e21;
  cursor: pointer;

  i {
    font-style: normal;
    font-size: 18px;
    margin-right: 10px;

    &:before {
      font-family: 'icomoon', sans-serif;
      content: "\e904";
      color: #f79e21;
    }
  }

  &.copied {
    border-color: #09ab09;

    i:before {
      content: "\e906";
      color: #09ab09;
    }
  }
}

.exchange-copy-text {
  font-size: 14px;
  line-height: 16px;
  color: #697082;
  text-transform: capitalize;
}

.payment-method-name {
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 8px;
  font-weight: 700;
  color: #000000;

  [class*="dark"] & {
    color: #ffffff;
  }
}

.payment-method-range, .payment-method-fee {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  margin-bottom: 4px;
  color: #697082;
}

.address-block-label {
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
  color: #697082;
}

.amount-variants {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-column-gap: 6px;
  padding-bottom: 30px;
  padding-top: 10px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #e4e6ee;

    [class*="dark"] & {
      background-color: #2f333d;
    }
  }
}

.amount-variant {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f3f7;
  border-radius: 10px;
  margin-top: 6px;
  font-size: 16px;
  line-height: 19px;
  color: #697082;
  cursor: pointer;

  [class*="dark"] & {
    background-color: #0a0a0a;
  }
}

.fastex-main-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;

  @media screen and (max-width: 979px) {
    margin-bottom: 20px;
  }
}

.fastex-mode-switcher {
  width: 30px;
  height: 30px;

  span {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #000000;

    [class*="dark"] & {
      background-color: #181a1e;
      color: #ffffff;
    }

    &:before {
      font-family: 'icomoon', sans-serif;
      content: "\e90f";
      font-size: 20px;
    }
  }

  input:checked {
    & + span {
      &:before {
        content: "\e90e";
        font-size: 16px;
      }
    }
  }
}

.address-list-navigation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;

  button {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    box-shadow: 0 0 6px 0 #0000001A;
    transition: box-shadow 240ms;
    cursor: pointer;
    pointer-events: auto;
    padding: 0;
    font-size: 12px;
    color: #697082;

    &:hover {
      box-shadow: 0 0 10px 0 #0000001A;
    }

    &:first-child {
      transform: rotateZ(90deg) translateY(50%);
    }

    &:last-child {
      transform: rotateZ(-90deg) translateY(50%);
    }
  }
}
