@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?rtsmsy');
  src:  url('fonts/icomoon.eot?rtsmsy#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?rtsmsy') format('truetype'),
    url('fonts/icomoon.woff?rtsmsy') format('woff'),
    url('fonts/icomoon.svg?rtsmsy#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-message:before {
  content: "\e910";
}
.icon-skype:before {
  content: "\e911";
}
.icon-moon:before {
  content: "\e90e";
}
.icon-sun:before {
  content: "\e90f";
}
.icon-back-button:before {
  content: "\e90d";
}
.icon-exchange1:before {
  content: "\e90a";
}
.icon-sell:before {
  content: "\e90b";
}
.icon-ftn:before {
  content: "\e90c";
}
.icon-copy:before {
  content: "\e904";
}
.icon-down:before {
  content: "\e907";
}
.icon-ft:before {
  content: "\e908";
}
.icon-exchange:before {
  content: "\e909";
}
.icon-checked:before {
  content: "\e906";
}
.icon-search:before {
  content: "\e900";
}
.icon-info:before {
  content: "\e901";
}
.icon-change:before {
  content: "\e902";
}
.icon-left:before {
  content: "\e903";
}
.icon-down-up:before {
  content: "\e905";
}
